<template>
  <div class="category-tree">
    <div class="font-26 butler-700">Category Tree</div>
    <ul>
      <li style="display: block" v-for="(category, index) in data" v-bind:key="index">
        <span class="butler-bold text-decoration-underline">CAT {{ index + 1 }}</span> -
        {{ category.name }}
        <ul>
          <li
            style="display: block"
            v-for="(layer, index) in category.layers"
            v-bind:key="index"
            class="pt-1"
          >
            <span class="butler-bold text-decoration-underline">LAY {{ index + 1 }}</span> -
            {{ layer.name }}
            <span class="sub-text">- Change of {{ layer.typeOfLayer }}</span>
            <ul>
              <li
                style="display: block"
                v-for="(toggle, index) in layer.toggles"
                v-bind:key="index"
                class="pt-1"
              >
                <span class="butler-bold text-decoration-underline">TOG {{ index + 1 }}</span> -
                {{ toggle.name }}
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
