import axios from 'axios';


const adminUrl = process.env.VUE_APP_API_URL;
const adminUrlIframe = process.env.VUE_APP_WL_API_URL;

const uploadUrl = process.env.VUE_APP_FILE_UPLOAD_URL;
const imageUpload = process.env.VUE_APP_IMAGE_UPLOAD_URL;

export default {
  uploadImage: (file, callback) => {
    console.log('reached here');
    const formData = new FormData();
    formData.append('file', file);
    axios
      .post(`${imageUpload}/image/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: localStorage.accessToken,
        },
      })
      .then((result) => {
        callback(result);
      });
  },

  async createProduct(data) {
    try {
      return axios.post(`${adminUrlIframe}/iframe/customization/createAdmin`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      });
    } catch (error) {
      return error;
    }
  },

  async updateProduct(params, data) {
    return axios.post(`${adminUrlIframe}/iframe/customization/updateDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async syncColorToggle(data) {
    return axios.post(`${adminUrlIframe}/iframe/customization/syncToggleWithPrimaryColor`, data, {
      headers: { 'Content-Type': 'application/json', Authorization: localStorage.accessToken },
    });
  },

  async getProduct(params) {
    return axios.get(`${adminUrlIframe}/iframe/customization/getDataAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },

  async getAllProductsForCustomization() {
    const data = {};
    return axios.get(`${adminUrlIframe}/iframe/product/getAllProductsForCustomization`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async getAllCustomers() {
    const data = {};
    return axios.get(`${adminUrl}/getAllCustomers`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createCustomAddition(data) {
    try {
      return axios.post(`${adminUrlIframe}/iframe/customization/saveAdditionDataAdmin`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      });
    } catch (error) {
      return error;
    }
  },
  async getOneAddition(params) {
    return axios.get(`${adminUrlIframe}/iframe/customization/getOneAdditionAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateAddition(params, data) {
    return axios.post(`${adminUrlIframe}/iframe/customization/updateAdditionDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async createCustomReplacement(data) {
    try {
      return axios.post(`${adminUrl}/customization/saveReplacementDataAdmin`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.accessToken,
        },
      });
    } catch (error) {
      return error;
    }
  },
  async getOneReplacement(params) {
    return axios.get(`${adminUrl}/customization/getOneReplacementAdmin/${params}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
  async updateReplacement(params, data) {
    return axios.post(`${adminUrl}/customization/updateReplacementDataAdmin/${params}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.accessToken,
      },
    });
  },
};
